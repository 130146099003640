// @flow
import { useEffect, useState, forwardRef } from "react";
import { useField } from "react-final-form";
import styled, { css } from "styled-components";
import { getConfig } from "@nested/config";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { Button } from "@nested/nest/src/components/Button/Button";
import { media } from "@nested/brand";
import { EmailPreviewPropertyCard } from "./EmailPreviewPropertyCard";

const { GOOGLE_CLOUD_STORAGE_API_URL } = getConfig();

export const TemplateVariable = styled.span`
  background-color: ${({ theme }) => theme.color.text.hague};
  padding: 2px 7px;
  color: white;
  border-radius: 8px;
  font-family: "Courier New", monospace;
  white-space: nowrap;
  font-weight: bold;
  font-size: 15px;
`;

export const previewButtonStyle = css`
  text-align: left;
  margin-bottom: 20px;
  ${media.tablet`
    display: none;
  `}
`;

const requestViewingText = css`
  font-weight: 500;
  font-style: italic;
`;

const ColumnHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px ${({ theme }) => theme.color.background.hague20};
  width: 100%;
  padding: 0 15px;
  height: 53px;
`;

export const mobileButtonsWrapper = css`
  padding: 20px;
  margin-right: -15px;
  margin-left: -15px;
  background: ${({ theme }) => theme.palette.hague100};
  ${media.tablet`
    display: none;
  `}
`;

const Tab = styled.button`
  background-color: transparent;
  border: none;
  color: ${({ selected, theme }) =>
    selected ? "#2D88D9" : theme.color.text.hague70};
  padding: 0;
  font-weight: 500;
  margin-left: 20px;
  font-size: 14px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid
    ${({ selected }) => (selected ? "#2D88D9" : "transparent")};
  cursor: pointer;
`;

export const fakeLink = css`
  text-decoration: underline;
  cursor: pointer;
  color: #2d88d9;
`;

const previewLinkStyle = css`
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-left: 0;
  color: ${({ theme }) => theme.color.text.hague70};
  padding: 0;
  font-size: 14px;
  font-weight: 500;
`;

// 625px here is the browser height of smaller iphones - SE, 8, etc
const previewWrapper = css`
  padding: 0 15px;
  height: ${({ $height }) =>
      $height <= 667 ? css`unset;` : css`calc(100 % + 10 px);`}
    ${media.tablet`
    overflow: auto;
    height: calc(100% - 70px);
  `};
`;

const PreviewBody = styled.div`
  width: 100%;
  ${media.tablet`
    display: block;
    width: 50%;
    height: 100%;
  `}
`;

export const EmailPreviewFooter = styled.div`
  padding: 10px 0;

  & p {
    color: ${({ theme }) => theme.color.text.hague70};
    font-size: 12px;
    margin-top: 20px;
  }
`;

export const Unsubscribe = styled.span`
  display: block;
  font-size: 16px;
  color: ${({ theme }) => theme.color.text.hague70};
  padding-bottom: 20px;
  border-bottom: solid 1px ${({ theme }) => theme.color.background.hague20};
`;

export const Paragraph = styled.p`
  color: ${({ theme }) => theme.color.text.hague};
  line-height: 1.4;
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;
  overflow-wrap: break-word;
`;

const EmailPreListParagraph = () => {
  return (
    <Paragraph>
      If you would like any more details, or to organise a viewing before it
      goes on Rightmove and Zoopla, please respond to this email or call me on{" "}
      <TemplateVariable>agent number</TemplateVariable>.
    </Paragraph>
  );
};

const EmailListedParagraph = () => (
  <Paragraph>
    If you'd like to organise a viewing you can press the{" "}
    <span css={requestViewingText}>Request a viewing</span> button below,
    respond to this email or call me on{" "}
    <TemplateVariable>agent number</TemplateVariable>.
  </Paragraph>
);

const EmailPreview = ({ subjectFieldProps, propertyDetails, preListDeal }) => (
  <div>
    <Paragraph>
      Hi <TemplateVariable>buyer name</TemplateVariable>,
    </Paragraph>
    <Paragraph data-test="email-custom-message-preview">
      {subjectFieldProps.input.value}
    </Paragraph>
    {preListDeal ? <EmailPreListParagraph /> : <EmailListedParagraph />}
    <Paragraph>
      Thanks, <br />
      <TemplateVariable>agent name</TemplateVariable> @ Nested
    </Paragraph>
    {propertyDetails && (
      <EmailPreviewPropertyCard propertyDetails={propertyDetails} />
    )}
    <EmailPreviewFooter>
      <Unsubscribe>
        <span css="text-decoration: underline">Unsubscribe</span> from property
        updates
      </Unsubscribe>
    </EmailPreviewFooter>
  </div>
);

const SmsPreview = ({ subjectFieldProps, preListDeal }) => (
  <div>
    <Paragraph>
      Hi <TemplateVariable>buyer name</TemplateVariable>,
    </Paragraph>
    {subjectFieldProps.input.value && (
      <Paragraph data-test="sms-custom-message-preview">
        {`${subjectFieldProps.input.value} ` || undefined}
      </Paragraph>
    )}
    {preListDeal ? (
      <Paragraph>
        Please let me know if you'd like to look before it goes online.
      </Paragraph>
    ) : (
      <Paragraph>
        Listing:{" "}
        <span css={fakeLink}>https://v.nested.com/example-listing</span>
        <br />
        Request a viewing:{" "}
        <span css={fakeLink}>https://v.nested.com/example-enquiry</span>
      </Paragraph>
    )}
    <Paragraph>
      <TemplateVariable>agent name</TemplateVariable> @ Nested
      {preListDeal && (
        <>
          {" "}
          - <TemplateVariable>agent number</TemplateVariable>
        </>
      )}
    </Paragraph>
    <Paragraph>
      P.S. to stop these messages:{" "}
      <span css={fakeLink}>https://v.nested.com/example</span>
    </Paragraph>
  </div>
);

type Props = {
  children: any,
  noTabs?: boolean,
  showEmailPreview?: boolean,
  setShowEmailPreview?: (arg: boolean) => void,
  showPreviewMobile?: boolean,
  className?: string,
  hasSMSPreview?: boolean,
};

export const EmailPreviewBasicLayout = forwardRef<
  Props,
  React$ElementRef<"div"> | null,
>(
  (
    {
      children,
      noTabs,
      showEmailPreview,
      setShowEmailPreview,
      className,
      hasSMSPreview = true,
    },
    previewRef,
  ) => {
    const height = window.innerHeight;
    return (
      <PreviewBody className={className} ref={previewRef}>
        <ColumnHeader>
          <p css={previewLinkStyle}>Preview</p>
          <div css="display: flex; justify-content: space-between;height: 100%;">
            {setShowEmailPreview && !noTabs && (
              <>
                <Tab
                  selected={showEmailPreview}
                  onClick={() => {
                    setShowEmailPreview(true);
                  }}
                >
                  Email
                </Tab>
                {hasSMSPreview && (
                  <Tab
                    selected={!showEmailPreview}
                    onClick={() => {
                      setShowEmailPreview(false);
                    }}
                  >
                    SMS
                  </Tab>
                )}
              </>
            )}
          </div>
        </ColumnHeader>
        <div css={previewWrapper} $height={height}>
          {children}
        </div>
      </PreviewBody>
    );
  },
);

export const fetchPropertyDetailsFromGCS = async (externalId: string) => {
  const fileName = `${externalId}/config.json`;

  const response = await fetch(
    `${GOOGLE_CLOUD_STORAGE_API_URL}/b/street-property-listings/o/${encodeURIComponent(
      fileName,
    )}?alt=media`,
    {
      headers: {
        "Cache-Control": "no-store",
      },
    },
  );

  return response.json();
};

export const MailoutPreview = ({
  preListDeal,
  externalId,
  onNext,
  formattedPrice,
  hasValidationErrors,
  refs,
}: {
  preListDeal: ?MailoutModalQuery_nestDeal,
  externalId: string,
  hasValidationErrors: ?boolean,
  onNext: () => void,
  formattedPrice: string,
  refs: {
    formRef: { current: React$ElementRef<"p"> | null },
    previewRef: { current: React$ElementRef<"div"> | null },
  },
}) => {
  const [propertyDetails, setPropertyDetails] = useState(null);
  const [showEmailPreview, setShowEmailPreview] = useState(true);
  const subjectFieldProps = useField("customMessage");
  const { formRef, previewRef } = refs;

  const getPropertyDetails = async () => {
    // Using formattedPrice from currentListPrice in the Nest to get around caching delay
    // when reducing the price. This is inline with what the backend uses.
    const propertyDetailsFromGCS = await fetchPropertyDetailsFromGCS(
      externalId,
    );
    const propertyDetail = { ...propertyDetailsFromGCS, price: formattedPrice };
    setPropertyDetails(propertyDetail);
  };

  useEffect(() => {
    getPropertyDetails();
  }, []);

  return (
    <EmailPreviewBasicLayout
      showEmailPreview={showEmailPreview}
      setShowEmailPreview={setShowEmailPreview}
      ref={previewRef}
    >
      {showEmailPreview ? (
        <EmailPreview
          subjectFieldProps={subjectFieldProps}
          propertyDetails={propertyDetails}
          preListDeal={preListDeal}
        />
      ) : (
        <SmsPreview
          subjectFieldProps={subjectFieldProps}
          preListDeal={preListDeal}
        />
      )}
      <div css={mobileButtonsWrapper}>
        <Button
          icon={faPen}
          buttonStyle="outline"
          large
          css={previewButtonStyle}
          type="button"
          onClick={() =>
            formRef.current?.scrollIntoView({
              behaviour: "smooth",
              block: "end",
            })
          }
        >
          Continue editing
        </Button>
        <Button
          buttonStyle="pink"
          data-test="choose-buyers-button"
          disabled={hasValidationErrors}
          onClick={onNext}
          large
        >
          Next: Choose buyers
        </Button>
      </div>
    </EmailPreviewBasicLayout>
  );
};
